import React from "react";
import { styled } from "goober";

import ChampionImg from "@/game-lol/components/ChampionImg.jsx";

export default function PostMatchDev({
  match,
  localPlayer,
}: {
  match: {
    gameId: number;
    platformId: string;
    participants: {
      puuid: string;
      championId: number;
      riotIdGameName: string;
      riotIdTagline: string;
    }[];
  };
  localPlayer: { puuid: string };
}) {
  return (
    <Container>
      <h4>{"☢️DEV ONLY: Select a player"}</h4>
      <ul className="list">
        {(match?.participants || []).map((p) => {
          return (
            <li key={p.puuid}>
              <a
                href={`/lol/match/${match.platformId}/${p.riotIdGameName}-${p.riotIdTagline}/${match.gameId}`}
                data-active={p.puuid === localPlayer?.puuid}
              >
                <ChampionImg
                  championId={p.championId}
                  size={32}
                  style={{ borderRadius: "50%" }}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </Container>
  );
}

const Container = styled("div")`
  & {
    display: grid;
    gap: var(--sp-2);
  }

  .list {
    display: flex;
    gap: var(--sp-2);
  }

  [data-active="true"] {
    img {
      box-shadow: 0 0 0 2px var(--yellow);
    }
  }
`;
